// components
// import SvgIconStyle from '../../../../components/SvgIconStyle';
import { ROLE } from 'src/config';
import Iconify from '../../../../components/Iconify';
// hooks
import useAuth from '../../../../hooks/useAuth';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

// const ICONS = {
//   user: getIcon('ic_user'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
// };

let navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        key: 'dashboard',
        title: 'Dashboard',
        path: '/employee/dashboard',
        icon: <Iconify icon={'bx:bxs-dashboard'} width={32} height={32} />,
      },
      {
        key: 'my-info',
        title: 'My Info',
        path: '/employee/my-info',
        icon: <Iconify icon={'ic:round-info'} width={20} height={20} />
      },
      {
        key: 'org-chart',
        title: 'Org Chart',
        path: '/employee/org-chart',
        icon: <Iconify icon={'icomoon-free:tree'} width={32} height={32} />,
      },
      {
        key: 'employees-performance',
        title: 'Performance',
        path: '/employee/performance',
        icon: <Iconify icon={'charm:rocket'} width={32} height={32} />,
        children: [
          {
            title: 'Goals',
            path: '/employee/goals',
            icon: <Iconify icon={'fluent:target-arrow-20-filled'} width={32} height={32} />,
          },
          { title: 'Reviews', path: '/employee/reviews' },
          { title: 'Surveys', path: '/employee/surveys' },
        ],
      },
      {
        key: 'employee-learning',
        title: 'Learning',
        path: '/employee/learning',
        icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} />,
        children: [
          { title: 'Pending Courses', path: '/employee/pending-courses' },
          { title: 'Completed Courses', path: '/employee/completed-courses' },
        ],
      },
      {
        key: 'employee-docs',
        title: 'Docs',
        path: '/employee/library',
        icon: <Iconify icon={'ep:document-copy'} width={32} height={32} />,
        children: [
          { title: 'HR Resources', path: '/employee/hr-resources' },
          { title: 'My Files', path: '/employee/my-files' },
        ],
      },
      {
        key: 'employee-time-off',
        title: 'Time Off',
        path: '/employee/time-off',
        icon: <Iconify icon={'ant-design:user-outlined'} width={32} height={32} />,
      },
    ],
  },
];

export default function NavConfig() {
  const { user } = useAuth();

  // remove time-off section depending on app settings value
  if (user !== null && user.app_settings && user.app_settings.length > 0) {
    const timeOffAppConfig = user.app_settings.find((app: any) => app.type === 'time_off');

    if (timeOffAppConfig && !timeOffAppConfig.is_enabled) {
      let tmpNavConfig = [...navConfig];
      tmpNavConfig[0].items = tmpNavConfig[0].items.filter(
        (item: any) => item.title !== 'Time Off'
      );

      return tmpNavConfig;
    }
  }

  // show training courses and tracker for managers
  if (user !== null && user.role_id.toString() === ROLE.MANAGER) {

    const itemToReplaceIndex = navConfig[0].items.findIndex(
        (item) => item.key === 'employee-learning'
    );

    if (itemToReplaceIndex !== -1) {
      navConfig[0].items[itemToReplaceIndex] = {
        key: 'manager-learning',
        title: 'Learning',
        path: '/employee/learning',
        icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} />,
        children: [
          { title: 'Pending Courses', path: '/employee/pending-courses' },
          { title: 'Completed Courses', path: '/employee/completed-courses' },
          {
            title: 'Training Tracker',
            path: '/employee/tracker',
          },
        ],
      };
    }
  }

  // return default nav config
  return navConfig;
}

// export default navConfig;
