import { SettingsValueProps } from './components/settings/type';

// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const CAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY || 'default-captcha-key';
export const APRYSE_LICENSE_KEY = process.env.REACT_APP_APRYSE_LICENSE_KEY || 'default-apryse-key';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.one; // as '/dashboard/one'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const COURSE_TYPES = {
  DIGITAL: 'digital',
  IN_PERSON: 'in_person'
};

export const SURVEY_QUESTION_TYPES = {
  MULTIPLE_CHOICE: 'multiple_choice',
  TEXT: 'text',
};

export const ROLE = {
  SUPER: '1',
  ADMIN: '2',
  EMPLOYEE: '3',
  MANAGER: '4',
  REVIEWER: '5',
};

export const BUILD_TYPE = {
  MOBILE: 'mobile',
  WEB: 'web'
};

export const USER_STATUS = {
  INACTIVE: '0',
  ACTIVE: '1',
};

export const TRAINING_TYPE = {
  IN_PERSON: 'in_person',
  DIGITAL: 'digital',
};


export enum UserReviewFormatEnum {
  bizhaven = 'format_bizhaven',
  document = 'format_document',
}

export enum DocumentParentTypeEnum {
  category = 'category',
  subcategory = 'subcategory',
}

export enum DocumentCategoryEnum {
  employee_reviews = 'employee_reviews',
  onboarding = 'onboarding',
}

export enum DocInputTypeEnum {
    signature = 'SIGNATURE',
    text = 'TEXT',
    date = 'DATE'
}

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};
