import React, { lazy } from 'react';
import { Navigate } from "react-router-dom";
import Loadable from "./loadable";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import { PATH_AFTER_LOGIN, ROLE } from "../config";
import EmployeeDashboardLayout from "../layouts/employee/dashboard";
import EmployeePendingCourses from "../pages/employee/learning/EmployeePendingCourses";
import EmployeeCompletedCourses from "../pages/employee/learning/EmployeeCompletedCourses";

const AdminGoalsPage = Loadable(lazy(() => import('../pages/admin/goal/AdminGoalsPage')));
const AdminGoalPage = Loadable(lazy(() => import('../pages/admin/goal/AdminGoalPage')));
const AdminSurveysPage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveysPage')));
const AdminSurveyCreatePage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveyCreatePage')));
const AdminSurveyEditPage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveyEditPage')));
const AdminSurveyPage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveyPage')));
const AdminReviewsPage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewsPage')));
const AdminReviewsDuePage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewsDuePage')));
const AdminReviewCreatePage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewCreatePage')));
const AdminReviewPage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewPage')));
const AdminReviewEditPage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewEditPage')));

const AdminTimeOffPage = Loadable(lazy(() => import('../pages/admin/AdminTimeOffPage')));

const EmployeeDashboardPage = Loadable(lazy(() => import('../pages/employee/EmployeeDashboardPage')));
const EmployeeCustomDocsPage = Loadable(lazy(() => import('../pages/employee/EmployeeCustomDocsPage')));
const MyInfo = Loadable(lazy(() => import('../pages/employee/EmployeeMyInfo')));

const EmployeeTrainingTrackerPage = Loadable(lazy(() => import('../pages/employee/learning/EmployeeTrainingTrackerPage')));
const EmployeeLearningPage = Loadable(lazy(() => import('../pages/employee/learning/EmployeeLearningPage')));
const EmployeeLearningDetailsPage = Loadable(lazy(() => import('../pages/employee/learning/EmployeeLearningDetailsPage')));
const EmployeeLearningModulePage = Loadable(lazy(() => import('../pages/employee/learning/EmployeeLearningModulePage')));
const EmployeeLearningQuizPage = Loadable(lazy(() => import('../pages/employee/learning/EmployeeLearningQuizPage')));
const EmployeeLearningQuizResultPage = Loadable(lazy(() => import('../pages/employee/learning/EmployeeLearningQuizResultPage')));

const EmployeeDocumentPage = Loadable(lazy(() => import('../pages/employee/EmployeeDocumentPage')));
const HRResourcesPage  = Loadable(lazy(() => import('../pages/employee/HRResourcesPage')));
const MyFiles  = Loadable(lazy(() => import('../pages/employee/MyFiles')));
const EmployeeVideosPage = Loadable(lazy(() => import('../pages/employee/learning/EmployeeVideosPage')));
const EmployeeProfilePage = Loadable(lazy(() => import('../pages/employee/EmployeeProfilePage')));
const EmployeeTakeSurveyPage = Loadable(lazy(() => import('../pages/employee/EmployeeTakeSurveyPage')));
const EmployeeReviewPage = Loadable(lazy(() => import('../pages/employee/EmployeeReviewPage')));
const EmployeeCompanyCalendar = Loadable(lazy(() => import('../pages/employee/EmployeeCompanyCalendar')));

const Organigram = Loadable(lazy(() => import('../sections/shared/orgchart')));

const EmployeeRoutes = [
    {
        path: 'employee',
        element: (
            <RoleBasedGuard accessibleRoles={[ROLE.EMPLOYEE, ROLE.MANAGER,  ROLE.ADMIN]}>
                <EmployeeDashboardLayout />
            </RoleBasedGuard>
        ),
        children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <EmployeeDashboardPage /> },
            // custom documents
            { path: 'custom-documents', element: <EmployeeCustomDocsPage /> },
            // learning / courses
            { path: 'learning', element: <EmployeeLearningPage /> },
            { path: 'pending-courses', element: <EmployeePendingCourses /> },
            { path: 'completed-courses', element: <EmployeeCompletedCourses /> },
            { path: 'my-info', element: <MyInfo /> },
            { path: 'tracker', element: <EmployeeTrainingTrackerPage /> },
            { path: 'learning/videos', element: <EmployeeVideosPage /> },
            { path: 'learning/:id', element: <EmployeeLearningDetailsPage /> },
            { path: 'learning/module/:id', element: <EmployeeLearningModulePage /> },
            { path: 'learning/module/:id/quiz', element: <EmployeeLearningQuizPage /> },
            { path: 'learning/module/:id/quiz/result', element: <EmployeeLearningQuizResultPage /> },
            // misc
            { path: 'library', element: <EmployeeDocumentPage /> },
            { path: 'hr-resources', element: <HRResourcesPage /> },
            { path: 'my-files', element: <MyFiles /> },
            { path: 'time-off', element: <AdminTimeOffPage /> },
            { path: 'profile', element: <EmployeeProfilePage /> },
            { path: 'org-chart', element: <Organigram /> },
            // goals
            { path: 'goals', element: <AdminGoalsPage /> },
            { path: 'goal/:id', element: <AdminGoalPage /> },
            // surveys
            { path: 'surveys', element: <AdminSurveysPage /> },
            { path: 'survey/:id/take', element: <EmployeeTakeSurveyPage /> },
            { path: 'survey/create', element: <AdminSurveyCreatePage /> },
            { path: 'survey/:id', element: <AdminSurveyPage /> },
            { path: 'survey/:id/edit', element: <AdminSurveyEditPage /> },
            // reviews
            { path: 'reviews', element: <AdminReviewsPage /> },
            { path: 'review/upcoming', element: <AdminReviewsDuePage /> },
            { path: 'review/create', element: <AdminReviewCreatePage /> },
            { path: 'review/:id', element: <EmployeeReviewPage /> },
            { path: 'review/:id/reviewee', element: <EmployeeReviewPage /> },
            { path: 'review/:id/edit', element: <AdminReviewEditPage /> },
            //  company events
            { path: 'company-events', element: <EmployeeCompanyCalendar /> }
        ],
    },
];

export default EmployeeRoutes;
